import { Link, useLocation } from 'react-router-dom';
import { BranchSettingsContext } from '../App';
import { useContext, useEffect } from 'react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export default function PaymentSuccess() {
    const { search } = useLocation();
    const {branchCustomId} = useContext(BranchSettingsContext);
    const params = new URLSearchParams(search);
    const setup_intent_id = params.get('setup_intent');

    useEffect(() => {
        if (setup_intent_id) {handleNewPaymentMethodSuccess()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [setup_intent_id]);


    const handleNewPaymentMethodSuccess = async () => {
        if (!setup_intent_id) {
          return;
        }
        const url = `${API_BASE_URL}/new_payment_method_success`;
        fetch(url, {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*', 
            'Content-Type': 'application/json',
            'Authorization': `Token ${SECRET_KEY}`,
            'x-branch-custom-id': branchCustomId || '',
          },
          body: JSON.stringify({
            "setup_intent_id": setup_intent_id
            })
        }).then(res => res.json()).then(res => {
        }).catch(error => {console.log('handleNewPaymentMethodSuccess error',error);});
    }

    return (
        <div className="text-slate-600 px-4 py-3" role="alert">
            {setup_intent_id ? <p>
                Thank you submitting your card for all your future payments. 
                <br/>Please sign the card authorization form that we emailed to you.
                <br/>For further questions please contact our support.
            </p>
            :
            <p>
                Payment submitted successfully.
                <br/>For further questions please contact our support.
            </p>
            }
        </div>
        
    )
}