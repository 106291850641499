import { PaymentElement, AddressElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from 'react';
import TermsCondition from '../components/TermsCondition';
import { BranchSettingsContext } from '../App';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const WWW_BASE_URL = process.env.REACT_APP_WWW_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export default function NewCardComponent({customer, setUpIntentId, clientSecret}) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {branchCustomId} = useContext(BranchSettingsContext);
  const [showTerms, setShowTerms] = useState(false);
  const [termsSignature, setTermsSignature] = useState(false);
  const [paymentElement, setPaymentElement] = useState(false);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    if (paymentElement?.complete && !termsSignature) {
      setShowTerms(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentElement])
    

  // Update the setup intent with signature as metadata
  const saveEvent = async ({name, eventId, eventData}) => {
    if (!name || !eventId || !eventData) {
        return;
        }
    setLoading(true);
    const url = `${API_BASE_URL}/event`;
    fetch(url, {
    method: 'post',
    headers: {
        'Accept': 'application/json, text/plain, */*', 
        'Content-Type': 'application/json',
        'Authorization': `Token ${SECRET_KEY}`,
        'x-branch-custom-id': branchCustomId || '',
    },
    body: JSON.stringify({
        "name": name,
        "event_id": eventId,
        "event_data": eventData
        })
    }).then(res => res.json()).then(res => {
      setLoading(false)
    }).catch(error => {console.log('submitPaymentDetails error',error);setLoading(false);});
  }


  const submitPaymentDetails = async () => {

    if (!termsSignature) {
      setError('Please sign the card authorization form.');
      return;
    }

    if (!stripe || !elements) {
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    let return_url = '';
    if (typeof window !== 'undefined') {
     return_url = `${WWW_BASE_URL}/${branchCustomId}/success`;
    }
    
    await saveEvent({
      name: 'submitPaymentDetails', 
      eventId: setUpIntentId, 
      eventData: {
        termsSignature: termsSignature, 
        paymentElement: paymentElement,
        setUpIntentId: setUpIntentId,
        clientSecret: clientSecret,
        customerId: customer?.id,
        }
      });

    const {error} = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: return_url },
    });
    setLoading(false);
    
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setError(error.message);
    } else {
      // Your customer will be redirected to your `return_url`
      setError('');
    }
    
  }
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Segoe UI, Roboto, Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
    return (
      <div className='sm:w-full sm:max-w-lg'>
        
        <div className="col-span-6">
          <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700"> Card Information </label>
          {error && <p className='text-red-500'>{error}</p>}
          <PaymentElement terms={{card:"always"}} onChange={(e)=>{setPaymentElement(e);e?.complete && setError('');e?.complete && setLoading(false)}} options={cardStyle} className='mt-1 py-3 text-md block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500'/>
          <AddressElement options={{mode: 'billing', fields: {'phone':'always'}}} onChange={(e)=>{setAddress(e.value)}}  className='mt-1 py-3 text-md block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500'/>
        </div>
        {/*  */}
        
          <div className="col-span-6 mt-4">
            <div>
              <label className="flex justify-between text-sm font-medium" onClick={()=> setShowTerms(!showTerms)}>
                  Review Credit Card Payment Authorization
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`w-6 h-6 transform ${showTerms? "rotate-180": "rotate-0"} transition-transform`}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </label>
              {showTerms && <TermsCondition customer={customer} address={address} completeSignatureForm={(signatureUrl)=>{setTermsSignature(signatureUrl)}} /> }
            </div>
          </div>
          
        <div className="px-4 py-3 text-right sm:px-6">
          <button
            onClick={() => {setShowTerms(false);submitPaymentDetails()}}
            disabled={loading || !paymentElement?.complete || !termsSignature}
            className="inline-flex justify-center 
              rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm 
              font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none 
              focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:bg-gray-300"
          >
            {loading? "Adding Card...": "Add Card"}
          </button>
        </div>
      </div>
    )
  }