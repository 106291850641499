import React, { useEffect, useState } from 'react';
import './App.css';
import {BrowserRouter as Router,  Routes , Route, useParams } from 'react-router-dom';
import NewCard from './pages/NewCard';
import PaymentSuccess from './pages/PaymentSuccess';
import Payment from './pages/Payment';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import Header from './components/Header';

// Promise context to make Stripe available to all components
const StripePromiseContext = React.createContext(null);
const BranchSettingsContext = React.createContext(null);
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BranchComponent = ({subdomainBranchId}) => {
  const [ stripePromise, setStripePromise ] = useState(null);
  const [ branchSettings, setBranchSettings ] = useState(null);
  const { pathBranchId } = useParams();
  const branchId = subdomainBranchId || pathBranchId;

  useEffect(() => {
    async function loadPromise(pk) {
      const stripePromiseResult = await loadStripe(pk);
      setStripePromise(stripePromiseResult);
    }
    async function loadBranchSettings() {
      const url = `${API_BASE_URL}/branch/${branchId}`;
      fetch(
        url,
        {method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_ADMIN_SECRET_KEY}`
          }
        }
      )
      .then(response => response.json())
      .then(data => {
        if (data?.branch) {
          setBranchSettings(data.branch);
          loadPromise(data.branch.stripe_public_key);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      })
    }
    if (branchId) {
      loadBranchSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!stripePromise) {
    return <p></p>;
  }

  return (
    <>
      {/* <header className="App-header">
          {branchSettings?.logo_url && <img src={branchSettings?.logo_url} className="logo" alt="logo" /> }
      </header> */}
      <Header logoUrl={branchSettings?.logo_url} />
      <div className='mainSection rounded-md p-2'>
        <BranchSettingsContext.Provider value={{branchSettings: branchSettings, branchCustomId: branchSettings?.branch_custom_id?.toLowerCase()}}>
          <StripePromiseContext.Provider value={stripePromise}>
            <Routes>
              <Route path="/:customerId/newCard" exact element={<NewCard/>} />
              <Route path="/newCard" exact element={<NewCard/>} />
              <Route path="/success" exact element={<PaymentSuccess/>} />
              <Route path="/:customerId/pay/:defaultAmount" exact element={<Payment/>} />
              <Route path="/:customerId/pay" exact element={<Payment/>} />
            </Routes>
          </StripePromiseContext.Provider>
        </BranchSettingsContext.Provider>
      </div>
    </>
  );
};

BranchComponent.propTypes = {  
  subdomainBranchId: PropTypes.string
};

const App = () => {
  const getSubdomain = () => {
    let host = window.location.host;//pay.branch.domain.com
    host = host.replace('www.','');
    const parts = host.split('.');
    // If we get more than 4 parts, then we have a subdomain
    if ((parts.length >= 3  && host?.includes('localhost')) || (parts.length >= 4)) {
      return parts[1];
    } else {
      return null;
    }
  };

  return (
    <div className="App">
      <Router>
        {!getSubdomain() ?
          <Routes>
            <Route path="/:pathBranchId/*" element={<BranchComponent/>} />
          </Routes>
          :
          <Routes>
            <Route path="/*" element={<BranchComponent subdomainBranchId={getSubdomain()}/>} />
          </Routes>
        }
      </Router>
    </div>
  )
}
export default App;
export { StripePromiseContext, BranchSettingsContext };
