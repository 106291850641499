import { useState } from 'react'
// import { Dialog } from '@headlessui/react'
// import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Add Payment Method', href: 'newcard' },
  { name: 'Make a Payment', href: 'pay'}
]

export default function Header({logoUrl}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const currentRoute = window.location.pathname;

  return (
    <header className="App-header">
        <div className="mx-auto relative">
            {logoUrl && <img src={logoUrl} className="logo mx-auto" alt="logo" />}
        </div>
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="lg:flex lg:gap-x-12">
            {navigation.map(item => (
                <a key={item.name} href={item.href}
                className={`rounded-md ${currentRoute?.toLowerCase()?.endsWith(item.href.toLowerCase())? "text-slate-900": "text-gray-500"} hover:text-slate-900 px-3 py-2 text-sm font-semibold`}>
                    {item.name}
                </a>
            ))}
            </div>
        </nav>
      
    </header>
  )
}
