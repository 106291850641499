import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { StripePromiseContext, BranchSettingsContext } from '../App';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { cleanName } from '../components/Utils';
import NewCardComponent from './NewCardComponent';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;


export default function NewCard() {
    const stripePromise = useContext(StripePromiseContext);
    const {branchCustomId} = useContext(BranchSettingsContext);
    let { customerId: customerIdParam } = useParams();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [customer, setCustomer] = useState(null);
    const [ clientSecret, setClientSecret ] = useState(null);
    const [ setUpIntentId, setSetUpIntentId ] = useState(null);
    const [ clickedFindCustomerButton, setClickedFindCustomerButton ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [placeResult, setPlaceResult] = useState(null);
    
    let streetNumber = '';
    let streetAddress = customer?.address?.line1 || '';
    let city = customer?.address?.city || '';
    let state = customer?.address?.state || '';
    let country = customer?.address?.country || '';
    let postalCode = customer?.address?.postal_code || '';
    
    if (placeResult) {
        streetNumber = placeResult?.address_components?.find((component) => component.types.includes('street_number'))?.long_name;
        streetAddress = placeResult?.address_components?.find((component) => component.types.includes('route'))?.long_name;
        city = placeResult?.address_components?.find((component) => component.types.includes('locality') || (component.types.includes('political') && component.types.includes('sublocality')))?.long_name;
        state = placeResult?.address_components?.find((component) => component.types.includes('administrative_area_level_1'))?.short_name;
        country = placeResult?.address_components?.find((component) => component.types.includes('country'))?.short_name;
        postalCode = placeResult?.address_components?.find((component) => component.types.includes('postal_code'))?.long_name;
    }

    const getPlaceFromSelectedAddress = async () => {
        if (!selectedAddress?.value?.place_id){
        setPlaceResult(null);
        return;
        }
        const result = await geocodeByPlaceId(selectedAddress?.value?.place_id);
        setPlaceResult(result[0]);

    }
    useEffect(() => { 
        getPlaceFromSelectedAddress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress]);

    useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (customer?.id) { createSetupIntent(); }}, [customer]);

    useEffect(() => {
        if (customerIdParam) {findCustomerByEmailOrId({customerIdorEmail:customerIdParam})}
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [customerIdParam]);



    const cardElementOptions = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {/*...*/},
      };
    
    const findCustomerByEmailOrId = async ({customerIdorEmail, clickedFindCustomerButton}) => {
        if (!customerIdorEmail) {
            return;
        }
        setLoading(true);
        let url = `${API_BASE_URL}/stripe/customer/${customerIdorEmail}`;
        if (customerIdorEmail?.includes('@')) {
            url = `${API_BASE_URL}/stripe/customer/?email=${customerIdorEmail}&create_if_not_exists=true`;
        }
        const options = {
            method: 'GET', 
            headers: {
                Authorization: `Token ${SECRET_KEY}`,
                'x-branch-custom-id': branchCustomId || ''
            }
        };
        fetch(url, options).then(res => res.json()).then(res => {
            if (res?.stripe_customer) {
                setLoading(false);
                setCustomer(res?.stripe_customer);
                setEmail(res?.stripe_customer?.email);
                setName(res?.stripe_customer?.name ? cleanName(res?.stripe_customer?.name) : '');
                setPhone(res?.stripe_customer?.phone);
            } else {
                setCustomer(null);
                setLoading(false);
                if (clickedFindCustomerButton) {
                    setClickedFindCustomerButton(true);
                }
            }
            setLoading(false);
        }).catch(error => {
            setCustomer(null);
            setLoading(false);
            if (clickedFindCustomerButton) {
                setClickedFindCustomerButton(true);
            }
        });
    }

    const createSetupIntent = async () => {
        if (!customer || !customer?.id) {
          return;
        }
        setLoading(true);
        const url = `${API_BASE_URL}/stripe/setup_intent`;
        fetch(url, {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*', 
            'Content-Type': 'application/json',
            'Authorization': `Token ${SECRET_KEY}`,
            'x-branch-custom-id': branchCustomId || '',
          },
          body: JSON.stringify({
            "customer_id": customer?.id,
            "metadata": {
              "email": email,
              "name": name,
              "phone": phone,
              "line1": `${streetNumber? streetNumber+ ' ':''}${streetAddress}`,
              "city": city,
              "state": state,
              "country": country,
              "postal_code": postalCode
            }
            })
        }).then(res => res.json()).then(res => {
          if (res?.setup_intent?.client_secret) {
            setClientSecret(res?.setup_intent?.client_secret);
            setSetUpIntentId(res?.setup_intent?.id);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }).catch(error => {console.log('createSetupIntent error',error);setLoading(false);});
    }


    

    return (
        <div className="mt-10 sm:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md text-left">
                {error &&
                    <div className="bg-white p-5">
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{error}</span>
                        </div>
                    </div>
                }
                <div className="bg-white px-4 py-5 sm:p-6">
                    <h3 className="text-md font-medium leading-6 text-gray-500 mb-4 text-center">New Payment Method</h3>
                    <div className="grid grid-cols-6 gap-6">
                        {stripePromise && customer && clientSecret &&
                            <div className="col-span-6">
                                <Elements stripe={stripePromise} options={cardElementOptions}>
                                    <NewCardComponent customer={customer} setUpIntentId={setUpIntentId} clientSecret={clientSecret} />
                                </Elements>
                            </div>
                        }

                        {!customer && clickedFindCustomerButton &&
                            <div className="bg-white col-span-6">
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <p>Customer does not exist. Please make sure the customer exists internally</p>
                                </div>
                            </div>
                        }

                        {!customer &&
                        <>
                            <div className="col-span-6">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="text"
                                    placeholder="Please enter the customer's email to get started"
                                    defaultValue={email || ''}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                                />
                            </div>
                            <div className="col-span-6">
                                <div className="px-4 py-3 text-right sm:px-6">
                                    <button
                                        onClick={()=>{findCustomerByEmailOrId({customerIdorEmail:email, clickedFindCustomerButton:true})}}
                                        disabled={loading || !email || !email?.includes('@')}
                                        className="inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:opacity-50"
                                        >
                                        Find Customer
                                    </button>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}