import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BranchSettingsContext } from '../App';

const WWW_BASE_URL = process.env.REACT_APP_WWW_BASE_URL;

export default function PaymentComponent({paymentMethodId, intentClientSecret, amount, toggleHistory, setError, updateSaveOnFile, parentLoading}) {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [saveOnFile, setSaveOnFile] = useState(true);
    const {branchCustomId} = useContext(BranchSettingsContext);

    useEffect(() => {
        updateSaveOnFile(saveOnFile)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveOnFile])

    const submitCardPaymentDetails = async (e) => {
        e.preventDefault();
        if ((amount && amount <= 1) || !amount) {
            setError && setError('Please enter an amount greater than $1.');
            return;
        }
        try {
            if ((!stripe || !intentClientSecret)) {
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
            }
            setLoading(true);
            const return_url = `/success`;
            let error = null;
            if (paymentMethodId) {
                const result = await stripe.confirmCardPayment(intentClientSecret, {payment_method: paymentMethodId});
                error = result?.error;
            } else {
                let return_url = '';
                if (typeof window !== 'undefined') {
                    return_url = `${WWW_BASE_URL}/${branchCustomId}/success`;
                }
                const result = await stripe.confirmPayment({ elements, confirmParams: { return_url: return_url}});
                error = result?.error;
            }
            if (error) {
                setError && setError(error?.message || 'Something went wrong. Please try again later.');
                setLoading(false);
            } else {
                setLoading(false);
                // Router push to success page
                navigate(return_url)
            }
        } catch (error) {
            setError && setError('Something went wrong. Please try again later.');
            setLoading(false);
            console.log(error)
        }
    }
    const cardStyle = {
        style: {
        base: {
            color: "#32325d",
            fontFamily: 'Segoe UI, Roboto, Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
            color: "#32325d"
            }
        },
        invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
        }
        }
    };
    return (
      <div>
        {!paymentMethodId &&
        <div>
            <div className="col-span-6">
                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700"> Card Information </label>
                <PaymentElement options={cardStyle} className='mt-1 p-3 text-md block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500'/>
            </div>
            <div className="col-span-6 mt-4">
                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700"> Billing Address </label>
                <AddressElement options={{mode: 'billing', fields: {'phone':'always'}}} />
            </div>
            <div className="relative flex gap-x-3 mt-4">
              <div className="flex h-6 items-center">
                <input id="saveOnFile" name="saveOnFile" type="checkbox" checked={saveOnFile} onChange={()=>setSaveOnFile(!saveOnFile)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                <label for="saveOnFile" className="font-medium text-gray-700 text-sm leading-6 ml-2">Save card on file</label>
              </div>
            </div>
        </div>
            
      
        }
      <div className="text-right">
        {loading ? 
            <div className="spinner" id="spinner"></div> 
            :
            <div className='space-x-2 my-2'>
                <button
                onClick={(e) => submitCardPaymentDetails(e)}
                disabled={loading || parentLoading}
                className="inline-flex justify-center rounded-md border border-transparent 
                    bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm 
                    hover:bg-orange-700 focus:outline-none focus:ring-2 
                    focus:ring-orange-500 focus:ring-offset-2
                    disabled:opacity-50 disabled:cursor-not-allowed
                    "
                >
                    Submit Payment
                </button>
                <button
                onClick={() => toggleHistory()}
                disabled={loading}
                className="inline-flex rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                    History
                </button>
            </div>
        }
      </div>
      </div>
    )
  }