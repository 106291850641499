import SignatureCanvas from "react-signature-canvas";

export default function TermsCondition({completeSignatureForm, customer, address}) {
    let sigPad = {}
    const addressToDisplay = address?.address || customer?.address;

    const clear = () => {
        sigPad.clear();
        completeSignatureForm(null)
    }


  return (
    <div>
          <div className="min-h-full">
                <div className="bg-white pb-4 pt-5 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      {/* <h3 className="text-base font-semibold leading-6 text-gray-900">Credit Card Payment Authorization</h3> */}
                      <div className="mt-2">
                        <p className="text-sm text-gray-600 leading-6">
                            You authorize charges to your credit card for all the amounts indicated in the Commercial Vehicle Short-Term Rental Agreement and related documents 
                            (“Agreement”).<br/>
                            A receipt for each payment will be provided to you and the charge will appear on your credit card statement.<br/>
                            You agree that no prior-notification will be provided for each charge.<br/><br/>

                            I, {customer?.name || ""} authorize Lux Credit Consultants LLC DBA Buggy or its affiliates (the “Company”) to charge my Credit Card indicated below 
                            for all amounts due for the rental of the vehicle under the Agreement.<br/>
                            Such amounts may include, but are not limited to, Rent, Additional Rent (including, but not limited to, insurance charges) and Fees,<br/>
                            as such terms are defined in the Agreement and immediately when they become due under the Agreement.
                        </p>
                        <div className="my-6 border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Billing Address</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{addressToDisplay?.line1 || ""}</dd>
                                </div>
                                <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Email</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customer?.email || ""}</dd>
                                </div>
                                <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Phone</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customer?.phone || address?.phone || ""}</dd>
                                </div>
                                {/* CANT SHOW CARD INFO - Payment Element doesn't give card info - will have to fetch from api*/}
                                {/* <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Card Information</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{last4 || ""} · {cvv || ""} · {zipCode || ""}</dd>
                                </div> */}
                            </dl>
                        </div>

                        <p className="text-sm text-gray-600 leading-6">
                            I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify the Company in writing immediately,<br/>
                            of any changes in my account information or termination of this authorization.<br/>
                            I acknowledge that the origination of Credit Card transactions to my account must comply with the provisions of applicable law.<br/>
                            I certify that I am an authorized user of this Credit Card and will not dispute these authorized transactions,<br/>
                            so long as the transactions correspond to the terms indicated in this authorization form.<br/>
                            In the event a payment is returned for insufficient or uncollected funds, or otherwise, I authorize the Company to resubmit the charge.<br/> 
                            I further authorize the Company to receive automatic updates of my account information 
                            from the credit card company in order to keep the payment information current.
                        </p>
                        <p className="text-sm py-4 text-gray-600 leading-6">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700"> Signature </label>
                            <SignatureCanvas 
                                ref={(ref) => { sigPad = ref }} 
                                penColor="black" 
                                onEnd={()=>{completeSignatureForm(sigPad.getTrimmedCanvas().toDataURL('image/png'))}}
                                canvasProps={{width: 500, height: 100, 
                                    className: "sigCanvas mt-1 block w-full rounded-md border-gray-500 border border-gray-100 shadow-lg focus:border-orange-500 focus:ring-orange-500 sm:text-sm"}} 
                            />
                            <button onClick={()=>clear()} className="rounded-md border border-transparent bg-gray-500 py-1 px-4 text-sm text-white mt-2"> Erase Signature </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
    </div>
  )
}