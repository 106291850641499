import { Elements } from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PaymentComponent from './PaymentComponent';
import { StripePromiseContext, BranchSettingsContext } from '../App';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const paymentTypes = [
  'Card',
  // 'Terminal'
]
export default function Payment() {
  const stripePromise = useContext(StripePromiseContext);
  const {branchCustomId} = useContext(BranchSettingsContext);
  let { customerId: customerIdorEmail, defaultAmount } = useParams();
  const [customerId, setCustomerId] = useState(null);
  const [amount, setAmount] = useState(defaultAmount || 0);
  const [paymentTypeTab, setPaymentTypeTab] = useState(paymentTypes[0]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [charges, setCharges] = useState([]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [saveOnFile, setSaveOnFile] = useState(true);
  const [ clientSecret, setClientSecret ] = useState(null);
  const [ paymentIntentId, setPaymentIntentId ] = useState(null);
  const elementOptions = {clientSecret: clientSecret, appearance: {/*...*/}};

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (customerId) {fetchPaymentMethods()}}, [customerId]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (amount && Number(amount) > 0 && !paymentIntentId) {createPaymentIntent({amount:amount})}}, [amount, customerId]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (customerId && paymentIntentId) {updatePaymentIntent()}}, [selectedPaymentMethodId, amount, saveOnFile]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (showHistory) {fetchRecentCharges()}}, [showHistory]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!amount || Number(amount) <= 0) {setError('Amount must be greater than 0')}}, [amount]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (customerIdorEmail) {setOrFindCustomerId();}}, [customerIdorEmail]);
  
  const setOrFindCustomerId = async () => {
    if (!customerIdorEmail) {
        return;
    }
    if (!(customerIdorEmail?.includes('@'))) {
        setCustomerId(customerIdorEmail);
        return
    }

    setLoading(true);
    let url = `${API_BASE_URL}/stripe/customer/?email=${customerIdorEmail}&create_if_not_exists=true`;
    const options = {
        method: 'GET', 
        headers: {
            Authorization: `Token ${SECRET_KEY}`,
            'x-branch-custom-id': branchCustomId || ''
        }
    };
    fetch(url, options).then(res => res.json()).then(res => {
        if (res?.stripe_customer) {
            setLoading(false);
            setCustomerId(res?.stripe_customer.id);
        } else {
            setCustomerId(null);
            setError('Customer not found');
            setLoading(false);
        }
        setLoading(false);
    }).catch(error => {
        setCustomerId(null);
        setError('Customer not found');
        setLoading(false);
    });
  }

  const fetchPaymentMethods = async () => {
    if (!customerId) { return; }
    setLoading(true);
    const url = `${API_BASE_URL}/stripe/payment_method/?customer_id=${customerId}`;
    const options = {
      method: 'GET', 
      headers: {
        Authorization: `Token ${SECRET_KEY}`,
        'x-branch-custom-id': branchCustomId || ''
      }
    };
    fetch(url, options).then(res => res.json()).then(res => {
    if (res?.payment_methods?.data) {
        setLoading(false);
        const stripePaymentMethods = res?.payment_methods?.data?.map(paymentMethod => {
            return {
                id: paymentMethod.id,
                card: paymentMethod.card,
                last4: paymentMethod.card.last4,
                brand: paymentMethod.card.brand,
                exp_month: paymentMethod.card.exp_month,
                exp_year: paymentMethod.card.exp_year,
                billing_details: paymentMethod.billing_details,
                created: paymentMethod.created,
                customer: paymentMethod.customer,
                type: paymentMethod.type
            }
        })
        setPaymentMethods(stripePaymentMethods);
        setSelectedPaymentMethodId(stripePaymentMethods[0]?.id);
    } else {
        setPaymentMethods([]);
        setSelectedPaymentMethodId(null);
        setLoading(false);
    }
    setLoading(false);
    }).catch(error => {console.log('fetchPaymentMethods error',error);setPaymentMethods([]);setSelectedPaymentMethodId(null);setLoading(false);});
  }

  const fetchRecentCharges = async () => {
    if (!customerId) { return; }
    setLoading(true);
    const url = `${API_BASE_URL}/stripe/charge/?customer_id=${customerId}`;
    const options = {
      method: 'GET', 
      headers: {
        Authorization: `Token ${SECRET_KEY}`,
        'x-branch-custom-id': branchCustomId || '',
      }
    };
    fetch(url, options).then(res => res.json()).then(res => {
    if (res?.charges?.data) {
      setLoading(false);
      const stripeCharges = res?.charges?.data?.map(charge => {
          return {
              id: charge.id,
              status: charge.status,
              created: charge.created,
              customer: charge.customer,
              amount: Number(charge.amount),
              currency: charge.currency,
              metadata: charge.metadata,
          }
      })
      setCharges(stripeCharges);
    } else {
      setCharges([]);
      setLoading(false);
    }
    setLoading(false);
    }).catch(error => {console.log('fetchRecentCharges error',error);setCharges([]);setLoading(false);});
  }

  const createPaymentIntent = async ({amount}) => {
    if (!customerId || paymentIntentId || (!amount || Number(amount) <= 0)) { return }
    setLoading(true);
    setError('');
    const url = `${API_BASE_URL}/stripe/payment_intent`;
    fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*', 
        'Content-Type': 'application/json',
        'Authorization': `Token ${SECRET_KEY}`,
        'x-branch-custom-id': branchCustomId || '',
      },
      body: JSON.stringify({
        "customer_id": customerId,
        "amount": Number(amount || 1),
        "metadata": {"source": "vgm_pay"},
        "payment_method_id": selectedPaymentMethodId === "new" ? null : selectedPaymentMethodId,
        "save_on_file": saveOnFile
        })
    }).then(res => res.json()).then(res => {
      if (res?.intent_client_secret && res?.payment_intent_id) {
        setClientSecret(res?.intent_client_secret)
        setPaymentIntentId(res?.payment_intent_id)
        setLoading(false);
      } else {
        if (res?.error) {
          setError(res?.error);
        }
        setLoading(false);
      }
    }).catch(error => {
      setError('An error occurred. Please try again later or contact support.');
      setLoading(false);});
  }

  const updatePaymentIntent = async () => {
    if (!customerId || !paymentIntentId) { return }
    setLoading(true);
    setError('');
    const url = `${API_BASE_URL}/stripe/payment_intent`;
    fetch(url, {
      method: 'put',
      headers: {
        'Accept': 'application/json, text/plain, */*', 
        'Content-Type': 'application/json',
        'Authorization': `Token ${SECRET_KEY}`,
        'x-branch-custom-id': branchCustomId || '',
      },
      body: JSON.stringify({
        "payment_intent_id": paymentIntentId,
        "amount": Number(amount),
        "payment_method_id": selectedPaymentMethodId === "new" ? null : selectedPaymentMethodId,
        "save_on_file": saveOnFile
        })
    }).then(res => res.json()).then(res => {
      if (res?.intent_client_secret) {
        setError('');
        setLoading(false);
      } else {
        setError(res?.error || 'An error occurred. Please try again later or contact support.');
        setLoading(false);
        setAmount(0);
      }
    }).catch(error => {
      setLoading(false);
      setError('An error occurred. Please try again later or contact support.');
      setAmount(0);
    });
  }
  
  return (
    <div className="mt-10 sm:mt-0">
        <div className="mt-5">
            <div className="flex">
                {paymentTypes.map((tab) => (
                <button
                    key={tab}
                    className={`px-4 py-2 ${
                    paymentTypeTab === tab
                        ? 'bg-slate-700 text-white'
                        : 'bg-white text-slate-700'
                    } border border-none rounded-t-md`}
                    onClick={() => setPaymentTypeTab(tab)}
                >
                    {tab}
                </button>
                ))}
            </div>
            <div className="overflow-hidden shadow-t-none shadow rounded-tl-none rounded-md text-left py-6 bg-white">
              <div className="bg-white px-4">
                <h3 className="text-md font-medium leading-6 text-gray-500 mb-4 text-center">New Payment</h3>
                {error && <div className="bg-red-100 mb-2 text-red-700 px-4 py-3 rounded relative">{error}</div>}

                {/* {!customer && clickedFindCustomerButton &&
                  <div className="bg-white col-span-6">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                      <p>Customer does not exist. Please make sure the customer exists internally</p>
                    </div>
                  </div>
                } */}
                {/* {!customerId &&
                  <>
                      <div className="col-span-6">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                          <input
                              type="text"
                              placeholder="Please enter the customer's email to get started"
                              defaultValue={email || ''}
                              onChange={(e)=>setEmail(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                          />
                      </div>
                      <div className="col-span-6">
                          <div className="px-4 py-3 text-right sm:px-6">
                              <button
                                  onClick={()=>{findCustomerByEmailOrId({email:email, clickedFindCustomerButton:true})}}
                                  disabled={loading || !email || !email?.includes('@')}
                                  className="inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:opacity-50"
                                  >
                                  Find Customer
                              </button>
                          </div>
                      </div>
                  </>
                } */}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700"> Amount to charge </label>
                      <input
                        type="number"
                        min={0}
                        value={amount}
                        onChange={(e)=>{setError('');(!e.target.value || isNaN(e.target.value) || (Number(e.target.value) <= 0)) ? setAmount(0) : setAmount(Number(e.target.value));}}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                        />
                  </div>

                  <div className="col-span-6">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700"> Card to charge </label>
                      <select value={selectedPaymentMethodId || 'none_selected'} onChange={(e)=>setSelectedPaymentMethodId(e.target.value)} className="select mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm">
                          <option value='none_selected' disabled>Select a card to charge</option>
                          {paymentMethods?.map((method) => (
                              <option key={method.id} value={method.id}>{method?.card?.brand?.toUpperCase()} ending in {method.card.last4}</option>
                          ))}
                          <option value='new'>Add payment information</option>

                      </select>
                  </div>

                  {stripePromise && clientSecret &&
                    <div className="col-span-6">
                      <Elements stripe={stripePromise} options={elementOptions}>
                        <PaymentComponent 
                          paymentMethodId={selectedPaymentMethodId === 'new' ? null : selectedPaymentMethodId}
                          intentClientSecret={clientSecret} 
                          amount={amount}
                          toggleHistory={()=>setShowHistory(!showHistory)}
                          setError={setError}
                          updateSaveOnFile={(newValue)=>setSaveOnFile(newValue)}
                          parentLoading={loading}
                        />
                          
                      </Elements>
                    </div>
                  }

                  {charges && charges.length > 0 && showHistory && !loading &&
                  <div className="col-span-6">
                    <div className="flex flex-col">
                      <div className="-my-2 overflow-x-auto sm:-mx-4 lg:-mx-8 max-h-80">
                        <div className="py-2 align-middle inline-block min-w-full  sm:px-6 lg:px-8">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Amount
                                  </th>
                                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                  </th>
                                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {charges.map((charge) => (
                                  <tr key={charge.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm text-gray-900">${(charge.amount/100).toFixed(2)}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <span className={`inline-flex items-center rounded-md ${charge.status==="succeeded" ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"}  px-2 py-1 text-xs font-medium`}>{charge?.status?.toUpperCase()}</span>

                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm text-gray-900">{new Date(charge.created * 1000).toLocaleString()}</div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}